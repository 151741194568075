:root{
    --orange: #fa9e23;
    --green: #33cb9e;
}

.spotMarker{
    color: var(--green);
    background-color: white;
    border-radius: 50px;
    font-family: 'Spoqa Han Sans Neo', 'sans-serif';
    font-weight: bold;
    text-align: center;
    border: 3px solid var(--green);
    padding-top: 15px;
 
    /* selected marker*/
    color: white !important;
    background-color: var(--green);
    position: relative;
    z-index: 999;
}

#map {
  height: 100%;
  width: 100%;
}

.spotMarker::after {
    content:'';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -15px;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 15px solid var(--green);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

.wave {
    opacity: 0.4;
    position: absolute;
    top: -3px;
    left: -3px;
    border: 1px solid var(--green);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    animation: drift 3000ms infinite linear;
    -webkit-animation: drift 3000ms infinite linear;
    border: 1px solid var(--green);
    background-color: #33cb9e40;
    z-index: 99;
  }
  
  .wave.-two {
    opacity: 0;
    animation-delay: 1s;
  }
  
  .wave.-three {
    opacity: 0;
    animation-delay: 2s;
  }
  
  
  @-webkit-keyframes drift {
    from {
      -webkit-transform: rotate(0deg) scale(0, 0);
      transform: rotate(0deg) scale(0, 0);
      opacity: 1;
    }
    to {
      -webkit-transform: rotate(360deg) scale(2, 2);
      transform: rotate(0) scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes drift {
    from {
      transform: rotate(0deg) scale(1, 1);
      opacity: 1;
    }
    to {
      transform: rotate(0) scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
.zoom-listener {
    position: fixed;
    z-index: 2000;
    top: 1rem;
    left:  48%;
    border-radius: 8px;
    background-color: rgb(52, 70, 103);
    color: white;
    padding: 0.5rem 1rem;
    font-weight: 700;
}


.custom-spot-marker{
  width: 32px;
  height: 49px;
  background-image: url(../../../resources/map/img/spot-marker.png);
  background-size: cover;
}