.fmodal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.6);
}
.fmodal > section {
    width: 90%;
    height: 95%;
    margin:0 auto;
    border-radius: .3rem;
    background-color: #fff;
    animation: modal-show .3s;
    overflow: hidden;
    position: relative;
}
.fmodal > section > button {
    position: absolute;
    top: 0;
    right: 35px;
    width: 15px;
    font-size: 40px;
    font-weight: bold;
    color: black;
    background-color: transparent;
    cursor: pointer;
    border: 0;
    z-index: 2;
}

.fmodal > section > .header {
    font-weight: bold;
    margin: 20px;
    font-size: 20px;
}


.fmodal > section > .input_wrapper {
    margin: auto;
    width: calc(100% - 40px);
    height: calc(100% - 90px);
    border: 1px solid black;
    padding: 10px 30px;
}
.fmodal > section > .input_wrapper > div{
    margin: 20px 0;
}
.fmodal > section > .input_wrapper > div > span{
    width: 80px;
    display: inline-block;
}
.fmodal > section > .input_wrapper #options{
    display: inline-block;
    width: 350px;
}
.fmodal > section > .input_wrapper > .direct_input_wrapper{
    display: none;
}

.fmodal > section > .input_wrapper .direct_input{
    width: calc(100% - 10px);
    border-color: hsl(0, 0%, 80%);
    outline: none;
    resize: none;
}
.fmodal > section > .input_wrapper > .phoneInfo{
    display: flex;

}
.fmodal > section > .input_wrapper > div > .small_txt{
    font-size: 13px;
    color: #aaa;
}
.fmodal > section > .input_wrapper > div > label > .radioBtn{
    margin-left: 20px;
}
.fmodal > section > .input_wrapper > .btn{
    background-color: #222222;
    color: white;
    width: 100px;
    float: right;
}


/* complete modal css */
.fmodal > section > .complete_txt_wrapper{
    text-align: center;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
}

.fmodal > section > .complete_txt_wrapper > .btn_wrapper .btn{
    width: 200px;
    border: 1px solid #222222;
    margin: 30px 10px;
}
.fmodal > section > .complete_txt_wrapper > .btn_wrapper .btn:hover{
    background-color: #222222;
    color: white;
    font-weight: bold;
}


.fmodal.openModal {
    display: flex;
    align-items: center;
    animation: modal-bg-show .3s;
}
@keyframes modal-show {
    from {
        opacity: 0;
        margin-top: -50px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}
@keyframes modal-bg-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}