/* width */
::-webkit-scrollbar {
    width: 5px !important;
}
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffffff;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888 !important;
    width: 1rem;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(180, 79, 79);
  }



.places-list-wrapper{
    position: absolute;
    z-index: 500;
    top: 0;
    width: 370px;
    height: 100%;
    background-color: white;
    transition:  0.2s;
    padding: 1.5rem;
}


.show {
    left: 0;
}

.hide {
    left: -370px;
}

.control-btn {
    position: absolute;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    top: 0;
    font-size: 20px;
    cursor: pointer;
    transition: .3s;
}

.control-btn.btn-show{
    right: 0px;
}

.control-btn.btn-show:hover{
    width: 56px;
}
.control-btn.btn-hide{
    right: -50px;
    background-color: rgba(0, 0, 0, 0.692);
    color: white;
}


.places-list-wrapper .header {
    font-weight: 700;
    margin-top: 0.5rem;
}


.places-list-wrapper .search {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.places-list-wrapper .search .filter-label {
    font-size: 12px;
    color: #8A8A8A;
}

.places-list-wrapper .search {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: 0.5rem;
}

.city-line-select {
    border-radius: 4px !important;
    border: 1px solid rgb(172, 172, 172);
    width: 100%;
    outline: none;
}

.city-line-select:hover {
    border-color: black;
}

.custom-select__control {
    border-radius: 20.5px !important;
    border-color: rgba(0, 0, 0, 0) !important;
    background-color: transparent;
    width: 100%;
    box-shadow: 0 0 0 0 transparent !important;
    padding-left: 0.3rem;
}

.custom-select__control--is-focused {
    border-color: rgba(0, 0, 0, 0) !important;
}

.custom-select__multi-value__remove{
    display: none !important;
}

.custom-select__multi-value{
    background-color: transparent !important;
    font-weight: 700;
    font-size: 15px;
}

.city-line-select-btn{
    background-color: white;
    z-index: 10;
    position: absolute;
    right: 0.3rem;
    border-radius: 20.5px;
    border: 1px solid black;
    padding: 0.2rem 0.8rem;
    cursor: pointer;
    transition: 0.3s;
}

.city-line-select-btn:hover {
    background-color: black;
    color: white;
}

.places-list-wrapper .filter .search .filter-toggle-btn{
    background-color: white;
    position: absolute;
    right: 0.3rem;
    border-radius: 20.5px;
    border: 1px solid black;
    padding: 0.2rem 0.8rem;
    cursor: pointer;
    transition: 0.3s;
}

.places-list-wrapper .filter .search .filter-toggle-btn:hover{
    background-color: black;
    color: white;
}

.filter-box {
    position: absolute;
    top: 3rem;
    background-color: white;
    right: 0;
    z-index: 999999;
    font-size: 10px;
    padding: 1rem;
    border: 1px solid black;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);
}

.filter-box::before{
    content: "^";
    position: absolute;
    font-size: 1rem;
    top: -13px;
    right: 1.4rem;
    padding: 0;
    background-color: white;
}

.filter-box .radio-box {
    display: flex;
    align-items: center;
    width: 177px;
    justify-content: space-between;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.filter-box .radio-box div {
    display: flex;
    align-items: center;
    flex: 1;
    cursor: pointer;
    position: relative;
}


.filter-box .radio-box div input[type="radio"] {
    /* margin-right: 0.2rem; */
    margin-left: 1rem;
    width: 0;
}

.filter-box .radio-box div input[type="radio"] + label::before {
    position: absolute;
    left: 2px;
    top: 3px;
    content: "";
    width: 10px;
    height: 10px;
    border: 1px solid black;
}



.filter-box .radio-box div input[type="radio"] + label:hover::after {
    position: absolute;
    left: 4px;
    top: -2px;
    content: "✔︎";
    font-size: 0.8rem;
    opacity: .5;
}

.filter-box .radio-box div input[type="radio"]:checked + label::after {
    position: absolute;
    left: 4px;
    top: -2px;
    content: "✔︎";
    font-size: 0.8rem;
}

.filter-control-btns {
    display: flex;
    justify-content: stretch;
    align-items: center;
    text-align: center;
}

.filter-control-btns span {
    flex: 1;
    border-radius: 17.5px;
    padding: 0.3rem .6rem;
    margin: 0 0.5rem;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
}

.filter-control-btns span.cancel {
    border: 1px solid #c4c4c4c4;
    transition: 0.3s;
}

.filter-control-btns span.cancel:hover {
    background-color: whitesmoke;
}

.filter-control-btns span.apply {
    background-color: #c4c4c4;
    border: 1px solid #000000;
    transition: 0.3s;
}

.filter-control-btns span.apply:hover {
    background-color: #d5d1d1;
}


.places-list-wrapper .search .search-input{
    width: 100%;
    border-radius: 4px;
    outline: none;
    height: 38px;
    padding: 0 1rem;
    border: 1px solid rgb(175, 175, 175);
    margin-top: .5rem;
}

.places-list-wrapper .search .search-input:hover{
    border-color: rgb(0, 0, 0);
}

.places-list-wrapper .search .search-input:focus{
    border-color: rgb(54, 90, 190);
}



.places-list-wrapper .sort{
    margin-top: 1rem;
    position: relative;
    border: 1px solid #8A8A8A;
    border-radius: 4px;
    color: #8A8A8A;
    padding: 1rem;
    padding-bottom: 0.5rem;
}

.places-list-wrapper .sort .sort-label{
    position: absolute;
    background-color: white;
    top: -0.7rem;
    left: 1rem;
    font-size: 0.8rem;
    padding: 0 0.5rem;
}

.places-list-wrapper .sort .sort-items {
    display: flex;
    list-style: none;
    justify-content: space-between;
    font-size: 0.8rem;
    padding: 0;
    margin-bottom: 0.2rem;
}

.places-list-wrapper .sort .sort-items input[type="radio"]{
    /* position: absolute; */

    width: 0;
}

.places-list-wrapper .sort .sort-items label {
    border: 1px solid #8A8A8A;
    border-radius: 17.5px;
    font-size: 12px;
    padding: 5px 15px;
    cursor: pointer;
    transition: 0.3s;
}

input[type="radio"] + label {
    cursor: pointer;
}

.places-list-wrapper .sort .sort-items input[type="radio"]:checked + label,
.places-list-wrapper .sort .sort-items label:hover {
    background-color: #C4C4C4;
    cursor: pointer;
}

.places-list-wrapper .sort .sort-tip{
    font-size: 12px;
    line-height: 11px;
}

.sortable-places-list {
    list-style: none;
    padding-left: 0;
    padding-right: 5px;
    height: 64%;
    overflow-y: scroll;
}

.place-item {
    border: 1px solid black;
    padding: 0.5rem 1rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    transition: .3s;
    background-color: white;
    cursor: pointer;
}

.place-item::after{
    content: '';
    display: block;
    position: absolute;
    top: 15px;
    right:20px;
    width: 10px;
    height: 10px; 
    border-right:1px solid #727272;
    border-bottom:1px solid #727272;
}

.place-item:hover::after{
    border-right:1px solid #000000;
    border-bottom:1px solid #000000;
}

.place-accordion.active .place-item{
    background-color: #C4C4C4;
}

.place-accordion.active .place-item::after {
    -moz-transform:rotate(-135deg);
    -webkit-transform:rotate(-135deg);
}

.place-accordion .place-item::after {
    -moz-transform:rotate(45deg);
    -webkit-transform:rotate(45deg);
}

.place-item .place-name {
    flex: 0.8;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.place-item .place-line-chart{
    flex: 1;
    padding-right: 2rem;
}

.place-item .place-count {
    flex: 0.3;
    color: #FF000F;
    font-style: italic;
    font-size: 12px;
}

.place-item .place-dropdown-btn {
    flex: 0.1;
    color: #C4C4C4;
    font-size: 1rem;
    cursor: pointer;
}

.place-dropdown-btn:hover .place-item {
    background-color: white;
}   

.place-item.active .place-dropdown-btn,
.place-item:hover .place-dropdown-btn {
    color: black;
}

.place-item .place-bookmark {
    position: absolute;
    top: -0.5rem;
    right: 2.2rem;
    font-size: 1rem;
}

.place-child {
    /* z-index: -1; */
    position: relative;
    margin-top: 1rem;
    padding: 1rem;
    border: 1px solid black;
    transition: ease-in-out 1s;
    /* animation: example 1s ease-in-out; */
    background-color: white;
}

.place-child::after {
    content: '';
    display: block;
    position: absolute;
    top: -6px;
    right:20px;
    width: 10px;
    height: 10px; 
    background: #FFFFFF;
    border-right:1px solid #000000;
    border-bottom:1px solid #000000;
    -moz-transform:rotate(-135deg);
    -webkit-transform:rotate(-135deg);
}


.place-child .place-child-header {
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    align-items: center;
}

.place-child .place-child-header svg {
    margin-top: -3px;
}

.place-child .place-child-header .bookmark-toggle{
    border: 1px solid black;
    border-radius: 17.5px;
    font-size: 11px;
    padding: 0.1rem 0.5rem;
    cursor: pointer;
    transition: 0.3s;
}

.place-child .place-child-header .bookmark-toggle:hover,
.place-child .place-child-header .bookmark-toggle.active{
    background-color: #C4C4C4;
}

.place-child-body{
    padding: 0;
    margin: 0;
    list-style: none;
}

.place-child-body li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #8A8A8A;
    margin-top: 0.5rem;

}

.place-child-body li span {
    display: inline-flex;
    align-items: center;
}

.place-child-body li span .child-index {
    border-radius: 50%;
    border: 1px solid #8A8A8A;
    font-size: 0.6rem;
    height: 15px;
    width: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;

}

.place-item-view-count{
    font-size: 12px;
}

.place-item-created-at{
    color: #8A8A8A;
}

.detailed-analysis{
    position: absolute !important;
    bottom: 1.5rem;
    left: 1.5rem;
    width: 315px;
    border-radius: 20.5px !important;
}

.ghost {
    opacity: 0;
}


.select-region-animation {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem
}

.select-region-animation .message {
    font-size: 12px;
    color: #7C7C7C;
    margin: 1rem 0;
}

.select-region-animation button .MuiButton-label {
    font-weight: 400;
    padding: 0 3rem;
}