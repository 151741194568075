.pck-sidebar-map {
    display: flex;
    width: 100%;
    height: -webkit-fill-available;
    justify-content: stretch;
}

.pck-sidebar {
    z-index: 99999;
    width: 70px;
    background-color: #222222;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: 100%;
    text-align: center;
}

.pck-sidebar a {
    text-decoration: none;
    color: #c4c4c4;
    font-weight: 700;
    font-size: 12px;
    font-weight: normal;
    transition: 0.3s;
    margin-top: 1rem
}

.pck-sidebar a:hover {
    color: #44FFC8;
    font-weight: bold;
}

.pck-sidebar a.selected {
    color: #44FFC8;
    font-weight: bold;
}

.pck-map {
    width: 100%;
    position: relative;
    overflow: hidden;
}