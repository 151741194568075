/* left menu css */
.leftMenu{
    width: 300px;
    border-right: 1px solid #222222;
    height: 100%;
    padding-top: 50px;
    padding-left: 30px;
    display: inline-block;
}

.leftMenu > div{
    margin-bottom: 50px;
}

.leftMenu > div > .menuGroup{
    font-weight: bold;
    margin-bottom: 10px;
}
.leftMenu > div > ul{
    padding-left: 32px;
}
.leftMenu > div > ul > a{
    list-style: none;
    position: relative;
    margin: 5px 0;
    text-decoration: none;
    color: black;
    display: block;
}

.leftMenu > div > ul > a::before{
    content: "·";
    position: absolute;
    left: -15px;
}

.leftMenu > div > ul > a:hover{
    font-weight: bold;
}
.leftMenu > div > ul > a.selected{
    font-weight: bold;
}

.rightPage{
    position: absolute;
    display: inline-block;
    width: calc(100% - 300px);
    height: 100%;
    overflow: auto;
}

/* 공통 */
.c_wrapper{
    padding: 50px 70px;
    position: relative;
    height: 100%;
}
.c_wrapper > .header{
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 70px;
}
.c_wrapper > .sub_title{
    font-size: 20px;
}
.txt_bold{
    font-weight: bold;
}
.r_green{
    width: 15px;
    height: 15px;
    line-height: 15px;
    background-color: green;
    display: inline-block;
    border-radius: 50px;
}
.r_red{
    width: 15px;
    height: 15px;
    line-height: 15px;
    background-color: red;
    display: inline-block;
    border-radius: 50px;
}
.icon-wrapper{
    cursor: pointer;
    position: relative;
}
.master-icon {
    position: absolute;
    left: -30px;
    top: 2px;
}

/* 계정 - 내 프로필.css*/
.userInfo > div{
    margin: 50px 0;
}
.userInfo > div div{
    display: inline-block;
}
.userInfo > div > .item1 {
    display: inline-block;
    width: 150px;
}
.userInfo > div > .item2{
    display: inline-block;
    width: 550px;
    line-height: 30px;
}
.userInfo > div > .item2 input{
    width: 400px;
}
.userInfo > div > .item3{
    display: inline-block;
    color: #aaa;
    width: 150px;
    text-align: right;
    cursor: pointer;
}
/* 계정 - 회원 탈퇴 - 안내 .css*/
.info_txt > div{
    margin-top: 40px;
}
.info_txt > table{
    width: 1000px;
    margin: 20px 0;
}
.info_txt > table > tbody > tr{
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
}
.info_txt > table > tbody > tr > td:first-child{
    border-right: 1px solid #aaa;
    text-align: center;
    padding: 0 50px;
    white-space: nowrap;
}
.info_txt > table > tbody > tr > td{
    padding: 40px;
}
.terminate_reason{
    margin: 40px 70px;
    border-top: 1px solid #aaa;
    padding: 40px 0;
}

/* 계정 - 회원 탈퇴 - 완료.css*/
.complete_wrapper{
    font-size: 20px;
    width: 100%;
    text-align: center;
}
.complete_wrapper > div:nth-child(2){
    margin: 20px auto 30px auto;
}


/*****************************************************************************/
/*                               유동인구 분석 현황                              */
/*****************************************************************************/


/*  센서 등록 페이지  ****************************** */
.sensor_wrapper{
    border-radius: 4px;
    width: 300px;
    height: 60px;
    display: inline-flex;
    border: 1px solid black;
    font-weight: bold;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 20px;
    margin-right: 20px;
}
.sensor_wrapper > div:not(:nth-child(2)){
    display: inline-block;
}
.sensor_icon{
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #ccc;
    font-weight: normal;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    text-align: center;
}
.input_sensor_wrapper > div:nth-child(2n){
    margin-left: 80px;
    margin-bottom: 100px;
    margin-top: 40px;
}
.input_sensor_wrapper > div:last-child{
    margin-bottom: 0;
}
#sensor_file_label{
    width: 500px;
    cursor: pointer;
    display: block;
}

#remove_btn{
    padding: 0;
    margin: 0;
    min-width: 25px;
}

#sensor-serial{
    border: none;
    padding: 0 0 0 0.25ch;
    width: 9ch;
    background: repeating-linear-gradient(
            90deg
            , dimgrey 0, dimgrey 1.5ch, transparent 0, transparent 2.25ch) 0 100%/8.25ch 2px no-repeat;
    font-size: 4ch;
    letter-spacing: 1.25ch;
}
#sensor-serial:focus{
    outline: none;
}

#mini_map{
    width: 740px;
    height: 300px;
    margin-top: 20px;
    margin-bottom: 20px;
    z-index: 1;
}
.address_list{
    width: 600px;
    height: 300px;
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    z-index: 2;
    border-radius: 0 0 10px 10px;
}
.address_list_none{
    display: none;
}
.address_list > .no_address{
    text-align: center;
    margin-top: 50px;
}
.im_here_btn {
    border-radius: 10px;
    border: 3px solid var(--green);
    background-color: var(--green);
    font-weight: bold;
    text-align: center;
    color: white;
    line-height: 24px;
}
.im_here_btn::after {
    content:'';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -20px;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-top: 10px solid var(--green);
    border-bottom: 10px solid transparent;
}
#outlined-basic {
    padding-right: 140px;
}





/* input[type=number] 컨트롤 패널없앰 */
input[type='number'] {
    -moz-appearance:textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

@keyframes motion {
    0% {
        transform: translateY(-10px);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}
