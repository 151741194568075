:root{
    --black_a2 : #000000CC;
}

.population_wrapper .population_info{
    background-color: var(--black_a2);
    width: 550px;
    position: absolute;
    top: 10px;
    z-index: 9999;
    color: white;
    padding: 10px 10px 10px 20px;
    transition: 0.2s;    
}

.population_wrapper .population_info_show{    
    left: 400px;
}

.population_wrapper .population_info_close{    
    left: 60px;
}

.population_wrapper .detail_info{
    font-size: 14px;    
    position: relative;
}

.population_wrapper .txt_line{
    text-decoration: underline;
    font-weight: bold;
}
.population_wrapper .show_more{
    position: absolute;
    right: 10px;
    bottom: 0;
    cursor: pointer;
}
.population_wrapper .show_more:hover{
    color: white;
    transform: scale(1.05);
}

.population_wrapper .population_compare_chart{
    background-color: var(--black_a2);
    position: absolute;
    width: 850px;
    top: 110px;
    z-index: 9999;
    color: white;
    height: 300px;
    display: flex;
    justify-content: space-evenly;
    transition: 0.2s;
}

.population_wrapper .no_show_chart{
    display: none;
}

.population_wrapper .population_compare_chart .left,
.population_wrapper .population_compare_chart .right{
    box-sizing: border-box;
    text-align: center;
}

.population_wrapper .population_compare_chart .left{
    width: 38%;
}


.population_wrapper .population_compare_chart .right{
    width: 60%;

}

.population_wrapper .population_compare_chart .title{
    font-weight: bold;
    font-size: 20px;
    margin: 10px 0;
}

.population_wrapper .population_compare_chart .chart{
    border: 1px solid #ccc;
    height: 240px;
    border-radius: 25px;
    background-color: white;
    line-height: 240px;
}