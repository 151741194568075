.info-header {
  font-size: 18px;
  margin-top: 2rem;
}

.org-list-wrapper {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid black;
}

.org-list-header {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  /* justify-content: stretch; */
}

.org-list-header .col-4 {
  text-align: center;
  flex: 3;
  border-bottom: 1px solid black;
  padding: 0.5rem 1rem;
}

.org-list-header .col-8 {
  text-align: center;
  flex: 7;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  padding: 0.5rem 1rem;
}

.org-empty-list {
  padding: 1rem 2rem;
  text-align: center;
  font-size: 12px;
}

.org-list-body .col-4-child {
  text-align: center;
  flex: 3;
  padding: 0.5rem 1rem;
  border: none;
}

.org-list-body .col-8-child {
  text-align: center;
  flex: 7;
  border-left: 1px solid rgb(117, 117, 117);
  padding: 0.5rem 1rem;
  font-size: 15px;
}

.org-list-body input[type="radio"] {
  display: none;
}

.org-list-body input[type="radio"] + label {
  width: 100%;
  display: flex;
}

.org-list-body input[type="radio"]:checked + label {
  background-color: #c4c4c4;
}

.info-footer button {
  width: 45%;
  height: 40px;
  /* border: 1px solid black; */
  border-radius: 20px;
}

.company-item {
  border-bottom: 1px solid #c4c4c4;
}

.company-item label {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
}

.org-modal-container {
  padding: 2rem;
}

@media screen and (max-width: 576px) {
  .org-modal-container {
    padding: 1rem;
  }
  .info-header {
    font-size: 15px;
    margin-top: 1rem;
  }
  .org-list-body .col-8-child {
    font-size: 13px;
  }
  .company-item label {
    font-size: 13px;
  }
  .org-list-wrapper {
    margin-top: 0rem;
  }
}
