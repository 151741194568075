.success-modal {
  width: 600px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 4rem;
  justify-content: center;
  background-image: url("../../resources/modal/clapping 2.png");
  background-position: center;
  background-repeat: no-repeat;
}
/*.success-modal div{*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/
.success-modal div:first-child {
  flex: 1;
  display: flex;
  align-items: center;
}

.success-modal div:last-child {
  flex: 0.3;
}
.success-modal p {
}

.success-modal button {
  border-radius: 29px;
  padding: 1rem 3rem;
}

.signup-success {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signup-success h1 {
  font-size: 40px;
}

.signup-success p {
  margin-top: 2rem;
  font-size: 20px;
}

.signup-success span {
  margin-top: 2rem;
  font-size: 20px;
}

.reset-pwd-modal {
  width: 900px;
  padding: 5rem 5rem;
  display: flex;
  flex-direction: column;
}

.reset-pwd-modal hr {
  width: 100%;
  margin-top: 2rem;
}

.reset-pwd-modal .input-group {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
  width: fit-content;
}
.reset-pwd-modal .input-group-header {
  margin-top: 2rem;
}
.reset-pwd-modal .input-group-header p {
  margin-top: 1rem;
}
.reset-pwd-modal .input-group .input-name {
  font-weight: bold;
  margin-right: 2rem;
}

.reset-pwd-modal .input-group .input-field {
  width: 300px;
}

.reset-pwd-modal .submit-btn {
  float: right;
  margin-top: 2rem;
  border-radius: 29px;
  width: 200px;
}

.reset-pwd-modal .submit-btn:hover {
  color: black;
}

/* AgencyModal */

.agency-modal {
  display: flex;
  flex-direction: column;
  padding: 3rem;
}

.agency-title {
  font-size: 30px;
}

.agency-type {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.agency-type div {
  position: relative;
}

.agency-type input[type="radio"],
.agency-list-item input[type="radio"] {
  display: none;
}

.agency-type input[type="radio"] + label {
  margin-right: 1.5rem;
  padding-left: 40px;
}

.agency-type input[type="radio"] + label::before {
  position: absolute;
  content: "";
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid black;
}

.agency-type input[type="radio"]:checked + label::after {
  position: absolute;
  content: "✓";
  left: 5px;
}

.agency-search-input {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
}

.agency-search-input button {
  padding: 0 2rem;
  margin-left: 1rem;
  border-radius: 30px;
  background-color: #ffffff;
}

.agency-search-input button:hover {
  background-color: black;
  color: white;
}

.agencies-list {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  /* margin-top: 1rem; */
  /* padding: 0rem 0rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 500px;
  overflow-y: scroll;
}

.agency-list-item {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.agency-list-empty-msg {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.agency-list-item input[type="radio"] + label {
  width: 100%;
  padding: 1rem 2rem;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  transition: 0.2s;
}

.agency-list-item input[type="radio"]:checked + label {
  background-color: #e0e0e0;
}

.agency-list-item label span {
  font-size: 12px;
  color: #8d8d8d;
  font-weight: 500;
  margin-top: 0.1rem;
}

.agency-list-item label span div {
  display: inline-block;
  width: 70px;
}

.modal-control-btns {
  display: flex;
  margin-top: 1rem;
}

.modal-control-btns .agency-cancel-btn,
.modal-control-btns .agency-submit-btn {
  border-radius: 20px;
  flex: 1;
}

.modal-control-btns .agency-cancel-btn {
  margin-right: 0.5rem;
}
.modal-control-btns .agency-submit-btn {
  margin-left: 0.5rem;
  background-color: whitesmoke;
}
.modal-control-btns .agency-submit-btn:hover {
  background-color: black;
  color: whitesmoke;
}

/* User List */

.user-list-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 0;
  background-color: rgb(255, 255, 255);
}

.user-list-modal ul {
  margin-top: 2rem;
  max-height: 400px;
  width: 500px;
  padding: 0 2rem;
  overflow-y: scroll;
}

.user-list-modal ul li {
  width: 100%;
  background-color: white;
  margin: 15px 0;
  padding: 1rem 2rem;
  border-radius: 10px;
  box-shadow: 0 0 10px 0px #dadada;
  transition: 0.3s;
}

.user-list-modal ul li:hover {
  box-shadow: 0 0 10px 0px #696969;
}

.user-list-modal ul li p {
  font-size: 20px;
}

.user-list-modal ul li p svg,
.user-list-modal ul li span svg {
  margin-right: 10px;
}

.login-wrapper {
  margin-top: 1rem;
}

.help-links {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.help-links span {
  font-size: 12px;
  margin-top: 0.5rem;
}
.help-links span a {
  color: blue;
  border-bottom: 1px solid transparent;
  margin-left: 1rem;
}

.help-links span a:hover {
  border-color: blue;
}

.comp-org-modal {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.comp-org-modal-grid {
  padding: 2rem;
}

.agency-type-title {
  font-weight: bold;
  font-size: 20px;
}
.org-label span {
  font-size: 14px !important;
}

@media screen and (max-width: 576px) {
  .comp-org-modal-grid {
    padding: 0.5rem !important;
  }
  .agency-title {
    font-size: 20px;
  }
  .agency-type-title {
    font-weight: bold;
    font-size: 17px;
  }
  .org-label span {
    font-size: 12px !important;
    padding: 0.2rem 0.3rem;
  }
  .agency-list-empty-msg {
    font-size: 13px;
  }
  .user-list-modal ul {
    width: auto;
  }
}
