:root{
    --black_a : #00000095;
    --blue : #2E79EB;
    --blue_a : #216588c0;
    --light_blue: #216588;
    /* #6ECEDA */
}

#ranking_wrapper{
    width: 600px;
    z-index: 9999;
    box-sizing: border-box;
    position: absolute;
    top: 10px;
    right: 0;
    color: white;
    overflow: hidden;
    padding: 10px;    
}
.ranking_wrapper_a{
    background-color: var(--blue_a);
}
.ranking_wrapper_na{
    background-color: var(--light_blue);
}

#ranking_wrapper > div{
    position: relative;
}
#icon_wrap{
    display: inline-flex;
    margin-left: 20px;
    transform: translateY(2px);
}


#txt_bold{
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
    line-height: 20px;
    position: relative;
}

#cur_location {
    font-weight: normal;
    font-size: 16px;
    display: inline-block;
    padding-left: 20px;
    position: absolute;
    right: 30px;
    top: 5px;
    text-decoration: underline;
}

.list_show{
    display: block;
}
.list_close{
    display: none;    
}

.ranking_title{
    position: absolute;
    padding: 20px;
    height: 620px;
    box-sizing: border-box;
}
.ranking_title > span{
    margin-right: 50px;
    color: #ddd;
    cursor: pointer;
}
.ranking_title .selected{
    font-weight: bold;
    color: white;    
    transform: scale(1.1);
}

.under_line{
    border-bottom: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    margin: 20px 0;
}


#ranking_wrapper .selected::after{
    content: " ●";
}


.ranking_list{
    height: 30px;
    transition: ease-in-out 0.5s;
}

.ranking_list, #ranking_list li{
    list-style: none;
    margin: 0;
    padding: 0;
}

.ranking_list .index{
    width: 30px;
    text-decoration: underline;
}
.moreRank{
    height: 500px;
}

.curRank{
    height: 30px;
}
.ranking_list li{
    height: 50px;
    transition: ease-in-out 0.5s;
    line-height: 30px;
    color: #ddd;
    cursor: pointer;
    padding-left: 20px;

}

.ranking_list .anime{
    /* animation: fadeIn 5s ease-in-out infinite; */
    font-weight: bold;
    color: white;
}

.ranking_list span{
    display: inline-block;
}

.ranking_list span:nth-child(1){
    margin-left: 20px;
}
.ranking_list span:nth-child(1)::after{
    border-bottom: 1px solid white;

}

.ranking_list span:nth-child(2){
    margin-left: 20px;
}

.ranking_list span:nth-child(3){
    position: absolute;
    right: 80px;
}


.show_moreRank_Btn{
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
}


@keyframes fadeIn{
    from{
        opacity: 0;
    }
    10% {
        transform: translateY(20px);
        opacity: 1;
    } 
    90% {
        transform: translateY(20px);
        opacity: 1;
    } 
    to{
        transform: translateY(40px);
        opacity: 0;
    }
}

.twink:after {
    top: 20px;
    left: 20px;
    position: absolute;
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #fd183a;
    border-radius: 50%;
    margin-left: 3px;
    -webkit-animation:blink 1s ease-in-out infinite alternate;
    -moz-animation:blink 1s ease-in-out infinite alternate;
    animation:blink 1s ease-in-out infinite alternate;
}


@-moz-keyframes blink{
    0% {opacity:0;}
    100% {opacity:1;}
}
@keyframes blink{
    0% {opacity:0;}
    100% {opacity:1;}
}