#dt_anl_wrapper{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    box-sizing: border-box;
    justify-content: space-around;
    position: absolute;
}

#dt_anl_wrapper #left_side, #right_side{
    box-sizing: border-box;
    padding: 40px;
    width: 50%;
    height: 100%;
    overflow: auto;
}
#dt_anl_wrapper #left_side{
    border-right: 2px solid black;
    overflow: hidden;
}
#dt_anl_wrapper #right_side{
    border-left: 2px solid black;
}

#dt_anl_wrapper #left_side #btnBookMark{
    position: absolute;
    right: 10px;
    top: -7px;
    cursor: pointer;
}


/* video_wrapper */
#dt_anl_wrapper #video_wrapper{
    width: 100%;
    height: 50%;
}
#dt_anl_wrapper #video_wrapper video{
    width: 100%;
    height: inherit;
    display: block;
    margin: 10px 0;
}



/* place info */
#dt_anl_wrapper #place_wrapper{
    display: block;
    width: 100%;
    border-bottom: 1px solid #aaa;
    padding-bottom: 10px;
}
#dt_anl_wrapper #place_wrapper #place_description{
    display: flex;
    line-height: 40px;
}
#dt_anl_wrapper #place_wrapper #place_description #place_name{
    font-weight: bold;
    font-size: 26px;
}
#dt_anl_wrapper #place_wrapper #place_description #place_address{
    margin-left: 10px;
}
#dt_anl_wrapper #place_wrapper #place_description #btn_share{
    border: 1px solid #aaa;
    background-color: #222222;
    color: white;
    position: absolute;
    right: 40px;   
}

/* sensor_wrapper */
#dt_anl_wrapper #sensor_wrapper{
    margin-top: 10px;
    padding-top: 10px;
    position: relative;
}
#dt_anl_wrapper .btn_selected{
    background-color: #222222 !important;
    color: white;
}
#dt_anl_wrapper .btn_sensor{
    border: 1px solid #aaa;
    margin-right: 10px;
    margin-top: 10px;
    color: #aaa;
}
#dt_anl_wrapper .download{
    position: absolute;
    right: 0;
    top: 5px;
    border: 1px solid #aaa;
}
    
#dt_anl_wrapper .btn_loading .download_txt {
    visibility: hidden;
    opacity: 0;
  }
  
  #dt_anl_wrapper .btn_loading::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: black;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }
  
  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }
  
    to {
      transform: rotate(1turn);
    }
  }



/* trendByPeriod_wrapper */
#trendByPeriod_wrapper{
    
}
.tooltips, .tooltips_t{
    background-color: #eee;
    color: white;
    border-radius: 50px;
    width: 15px;
    height: 15px;
    display: inline-block;
    line-height: 15px;
    text-align: center;
    margin-left: 10px;
    font-weight: normal;
    position: relative;
    cursor: default;
}
.tooltips_t:hover::before{
    position: absolute;
    background-color: #eee;
    color: #999;
    content: attr(data-content);
    width: max-content;
    height: 50px;
    font-size: 14px;
    text-align: left;
    border-radius: 10px;
    top: -59px;
    left: -7px;
    display: flex;
    align-items: center;
    padding: 10px;
    white-space: pre;
}
.tooltips_t:hover::after{
    border-top: 8px solid #eee;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    content:"";
    position:absolute;
    top:-10px;
    left:0;
}

#trendByPeriod_wrapper #select_date{
    color: #aaa;
    margin: 10px;
}
#trendByPeriod_wrapper #select_date span{
    cursor: pointer;
    margin: 0 5px;
}
#trendByPeriod_wrapper #select_date span:not(:last-child):after{
    content: "|";
    padding-left: 10px;
    font-weight: normal;
    color: #aaa;
}
#trendByPeriod_wrapper #select_date :first-child{
    margin: 0 5px 0 0;
}
.period{
    margin: 10px;
}
.date_selected{
    font-weight: bold;
    color: black;
}
.report_result{
    border: 1px solid #ccc;
    padding: 20px 20px 15px 20px;
}
.report_result div{
    padding-bottom: 5px;
}


/* populationReport */
.populationReport .report_title{
    background-color: #ccc;
    width: inherit;
    margin-top: 30px;
    padding: 10px 10px 10px 20px;
    border-radius: 10px;
    position: relative;

}
.populationReport .report_chart{
    width: 100%;
    transition: all 0.5s;
    overflow: hidden;

}
.populationReport .show{
    height: 1080px;
}
.populationReport .close{
    height: 0;
}
.populationReport .chart_wrapper{
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0 0; 
}
.populationReport .charts{
    border: 1px solid #ccc;
    margin: 15px 0;
    padding: 20px;
    position: relative;
    display: inline-block;
}
.populationReport #chart1{
    width: 58%;
}
.populationReport #chart2{
    width: 38%;
}
.populationReport .charts::before{
    position: absolute;
    width: 100px;
    background-color: black;
    top: -15px;
    font-weight: bold;
    color: white;
    text-align: center;
    padding: 5px;
    content: attr(data-content);
}
.populationReport .btn_slide{
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    cursor: pointer;
}
.option_wrapper{
    display: flex;
    justify-content: space-between;
    margin: 30px 0 10px 0;
}

.option_wrapper .picker{
    border: 1px solid black;
    width: 40%;
    height: 200px;
    position: relative;
}

.positionPicker_wrapper{
    border: none !important;
    display: flex;
    align-content: space-around;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.option_wrapper .positionPicker,
.option_wrapper .filePicker{
    border: 1px solid black;
    width: 100%;
    height: 100%;
}
.option_wrapper .filePicker{
    margin-top: 20px;
}

.option_wrapper .filePicker::before{
    position: absolute;
    content: "STEP2. 파일업로드";
    top: calc(50% + 10px);
    transform: translateY(-50%);
    left: 10px;
    width: 150px;
    text-align: center;
    font-weight: bold;
    background-color: white;
}

.option_wrapper .positionPicker::before{
    position: absolute;
    content: "STEP1. 장소 선택";
    top: -12px;
    left: 10px;
    width: 150px;
    text-align: center;
    font-weight: bold;
    background-color: white;
}
.option_wrapper .dataPicker1::before{
    position: absolute;
    content: "STEP2. 날짜 선택";
    top: -12px;
    left: 10px;
    width: 150px;
    text-align: center;
    font-weight: bold;
    background-color: white;
}
.option_wrapper .dataPicker2::before{
    position: absolute;
    content: "STEP3. 날짜 선택";
    top: -12px;
    left: 10px;
    width: 150px;
    text-align: center;
    font-weight: bold;
    background-color: white;
}
.option_wrapper .picker_btn{
    width: 15%;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.option_wrapper .picker_btn button{
    width: 100%;
    height: 48%;
    display: block;
    border: 1px solid black;
}
.option_wrapper .picker_btn #reset{
    height: 30%;
    background-color: transparent;
}
.option_wrapper .picker_btn #analyze{
    height: 65%;
    background-color: #ccc;
}
.report_chart .tipBox{
    width: 100%;
    background-color: #eee;
    color: #999;
    padding: 10px;
    font-size: 12px;
}
.report_chart img{
    width: 100%;
    padding-bottom: 20px;
}

/* common css */
.txt_bold{
    font-weight: bold;
}
.txt_bold_big{
    font-weight: bold;
    font-size: 20px;
}
.btn{
    padding: 0 10px;
    height: 36px;
    line-height: 30px;
    border-radius: 50px;
}
.btn:hover{
    background-color: #22222210;
    color: #aaa;
}

.txt_gray{
    color: #888;
    width: calc(100% - 50px);
}


@keyframes motion {
    0% {
      transform: translateY(-10px);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}

/* scroll *******************/
* ::-webkit-scrollbar{
    width: 10px;
    height: 7px;
}
* ::-webkit-scrollbar-thumb{
    background-color: #2f3542;
    border-radius: 100px;
}
* ::-webkit-scrollbar-track{
  /*  background-color: grey; */
}