:root{
    --black_a : #00000095;
}
#chartView{
    width: 600px;
    z-index: 9998;
    height: calc(100% - 70px);
    position: absolute;
    transition: ease-in-out 0.5s;
    top: 70px;
    right: 0;
    background-color: var(--black_a);
    box-sizing: border-box;
}
.chartView_open{
    transform: translateX(100%);    
}
.chartView_close{
    transform: translateX(0);    
}
#charView_wrapper{
    padding-top: 50px;
    width: 100%;
    height: calc(100% - 70px);
    position: relative;
}
#openBtn{
    z-index: 999;
    transition: 0.5s;
    cursor: pointer;
    width: 200px;
    height: 50px;
    color: white;
    border: none;
    border-radius: 25px 0 0 25px;
    position: absolute;
    top: 0px;
    text-align: center;
    line-height: 50px;
    font-weight: bold;
}
.openBtn_open{
    transform: translateX(0);
    background-color: transparent;
}
.openBtn_close{    
    background-color: var(--black_a);
    transform: translateX(-200px);
    font-weight: normal !important;
}

#openBtn span{
    display: inline-block;
    margin-right: 5px;

}
/* daily weekly custom */
#select_topic{
    width: 100%;
    height: 50px;
    color: #ccc;
    border-bottom: 0.1px solid #ccc;
    position: relative;
    margin-bottom: 10px;
    padding-left: 20px;
}
#select_topic span{
    margin: 10px 30px 10px 0;
    cursor: pointer;
    display: inline-block;

}
#select_topic span:hover{
    font-weight: bold;
}
#select_topic .selected{
    font-weight: bold;
    color: white;
}

#chartItem_wrapper{
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 0px 20px;
}


.item_wrapper{
    width: 100%;
    height: 300px;
    margin: 10px 0;
    position: relative;
    background-color: #ffffff60;
    font-size: 14px;
    border-radius: 10px;

}

.left_motion{
    animation: motion_l 1s infinite;
}
.right_motion{
    animation: motion_r 1s infinite;
}


.item_wrapper .chart_wrapper{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: white;
    padding: 30px;
    border-radius: 10px;
}
.chart_wrapper #btnBookMark{
    position: absolute;
    right: 10px;
    top: -10px;
    cursor: pointer;
}
.item_wrapper #place_description{
    border-bottom: 1px solid black;
}
.item_wrapper #place_name{
    font-weight: bold;
    font-size: 20px;
    margin-right: 10px;
}

.item_wrapper .place_pck_text{
    margin: 5px 0;
}

.item_wrapper #btn_share{
    font-size: 14px;
    border: 1px solid #aaa;
    color: white;
    position: absolute;
    right: 30px; 
    top: 40px;
    cursor: pointer;
    background-color: #ddd;
}

.item_wrapper .btn_share{
    background-color: #222222 !important;
}

.item_wrapper .btn_sensor{
    border: 1px solid #aaa;
    margin-right: 10px;
    color: #aaa;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
}

.place_pck_text .btn_selected{
    background-color: #222222 !important;
    color: white;
}

.item_wrapper .blur{
    filter: blur(5px);
    width: 100%;
    position: relative;
    z-index: 2;
}

.item_wrapper .share_txt{
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    width: inherit;
    text-align: center;
    z-index: 3;
}

.item_wrapper .small_chart{
    position: relative;
    z-index: 1;
}

.item_wrapper .txt_underline{
    text-decoration: underline;
}

/* no Data */
.item_wrapper .noData_wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
.item_wrapper .noData_wrapper div{
    text-align: center;
    margin: 10px;
    color: #ccc;
}


@keyframes motion_l {
    0% {
      transform: translate(-3px, -2px) scale(1);
    }
    50% {
        transform: translate(3px, -2px) scale(1);
    }
    100% {
        transform: translate(-3px, -2px) scale(1);
    }
}

@keyframes motion_r {
    0% {
        transform: translate(-6px, -2px) scale(1);
      }
      50% {
          transform: translate(0px, -2px) scale(1);
      }
      100% {
          transform: translate(-6px, -2px) scale(1);
      }
  
}

/* scroll *******************/
* ::-webkit-scrollbar{
    width: 10px;
    height: 7px;
}
* ::-webkit-scrollbar-thumb{
    background-color: #2f3542;
    border-radius: 100px;
}
* ::-webkit-scrollbar-track{
  /*  background-color: grey; */
}