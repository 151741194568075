.modal-wrapper {
  padding: 3rem !important;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.privicy-modal-text {
  font-size: 13px;
}

.modal-title {
  font-size: 30px;
}

@media screen and (max-width: 576px) {
  .modal-wrapper {
    padding: 1rem !important;
  }

  .privicy-modal-text {
    font-size: 12px;
  }
  .modal-title {
    font-size: 20px !important;
  }
}
