.c-modal {
    width: 800px;
    height: 500px;
    background-color: white;
    display: flex;
    padding: 50px !important;
}
.s-modal {
    width: 650px;
    height: 600px;
    background-color: white;
    display: flex;
    padding: 50px !important;
}
.close-btn {
    position: absolute;
    top: 0;
    right: 40px;
    width: 15px;
    font-size: 40px;
    font-weight: bold;
    color: black;
    background-color: transparent;
    cursor: pointer;
    border: 0;
    z-index: 2;
}
.modal-title {
    font-size: 30px;
    margin-bottom: 30px;
}
.modal-sub-title{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}
.mt-30 {
    margin-top: 30px;
}