/*not found*/
.notfound {
    display: block;
    width: 100%;
    height:100vh;
    background:#f5f5f5;
}
.notfound h1 {
    display: block;
    position: relative;
    font-size:440px;
    font-weight: 800;
    text-align: center;
    color: #dceae2;
}
.notfound h1 span {
    display: block;
    width:70px;
    position: absolute;
    top:42%;
    left:50%;
    transform:translate(-50%, -50%);
}
.notfound p {
    margin-top:-40px;
    font-size:26px;
    font-weight: 700;
    text-align: center;
    color:#282828;
}
.notfound small {
    display: block;
    margin:40px auto;
    font-size:18px;
    line-height: 1.6;
    text-align: center;
    color: #282828;
}
.notfound a {
    display: block;
    margin: 20px auto;
    width: 200px;
    height:50px;
    line-height: 50px;
    border-radius: 4px;
    border:1px solid #00934B;
    font-size: 18px;
    font-weight:500;
    color:#00934B;
    text-align: center;
    transition: all .3s ease-in;
}
.notfound a:hover {
    background:#00934B;
    color: #fff;
}
/*company*/
/*.hello {
    background:url("../resources/homepage/compay-bg.png") no-repeat right top;
}*/
.c-txt {
    position: relative;
    float: left;
    width:55%;
    margin-top: 40px;
    padding-left: 64px;
    box-sizing: border-box;
    font-size:18px;
    line-height:1.4;
    color:#282828;
}
.c-txt strong {
    font-size: 30px;
    font-weight: 500;
    color:#00934B;
    line-height: 1.4;
}
.c-txt::before {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 0;
    width: 3px;
    background-color: #00934B;
}
.c-txt p {
    word-break: keep-all;
}
.c-txt p + p {
    margin-top:30px;
}
.c-txt .ceo {
    margin-top:70px;
}
.c-img img {
    width:45%;
    float:right;
    animation-name: bounce;
    animation-duration: 3s;
    animation-iteration-count: 3;
    animation-delay: 1s;
    animation-direction: alternate;
}
@keyframes bounce {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-100px); }
    100% { transform: translateY(0); }
}

.ceo em {
    display:inline-block;
    margin: 0 0 8px;
    font-size:24px;
    vertical-align: middle;
}
.vision {
    background:#F6FBF8;
}
.vision .sub-title {
    margin-top: 50px;
    text-align:center;
}
.v-img {
    width:76%;
    margin: 60px auto 50px;
}
.v-img.mobile {
    display: none;
}
.v-txt strong {
    display: block;
    margin-bottom: 20px;
    font-size:30px;
    text-align: center;
}
.v-txt p {
    width:60%;
    margin: 0 auto;
    font-size:18px;
    line-height:1.4;
    color:#282828;
    text-align: center;
}
.business .sub-title {
    margin-top: 50px;
}
.cs-service {
    margin: 60px auto 50px;
}
.cs-service ul li {
    display:flex;
    flex-direction: row;
    float:left;
    width:46%;
    margin:0 0 50px;
}
.cs-service ul li:nth-child(even) {
    margin-left:8%;
}
.sc-icon {
    margin-right:30px;
}
.cs-txt {
    font-size:18px;
    line-height:1.6;
    color:#282828;
}
.cs-txt strong {
    display:block;
    font-size:24px;
    font-weight:500;
    margin-bottom:8px;
}
.cs-service ul:after {
    content:"";
    display:block;
    clear:both;
}
/*company*/

/*smart safety service*/
#sub-container .section-in.smart-sv {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding:0 0 150px;
}
.phone-ex {
    width:46%;
    margin:20px auto;
}
.smart-sv .c-txt {
    width:50%;
    padding-left:0;
}
.smart-sv .c-txt strong {
    color:#000;
}
.smart-sv .c-txt strong span {
    font-weight:700;
}
.smart-sv .c-txt::before, .history-in .c-txt::before {
    display:none;
}
.download {
    margin-top:30px;
}
.download a {
    display: inline-block;
    margin-right:15px;
    width:210px;
    height:64px;
    line-height:62px;
    font-size:0;
    text-align:center;
    border-radius:35px;
    border:1px solid #8d8d8d;
    vertical-align: middle;
}
.download a img {
    width:140px;
}
/*smart safety service*/

/*customer*/
.tabs {
    margin-bottom: 40px;
}
.tabs li {
    float:left;
    margin-right:16px;
    width:180px;
    height:52px;
    line-height:52px;
    font-size:18px;
    font-weight: 400;
    text-align: center;
    border-radius: 50px;
    border:1px solid #000;
    color:#000;
    cursor: pointer;
}
.tabs li.on {
    font-weight: 600;
    border-color: #00934B;
    background:#00934B;
    color:#fff;
}
.sv-tabs li {
    width:200px;
}
.tabs:after {
    content:"";
    display:block;
    clear:both;
}
/*customer*/

/*history*/
.history-in .c-txt {
    width:80%;
    padding-left:0;
    margin-top: 0;
}
.history-kinds + .history-kinds {
    margin-top:2%;
}
.history-kinds ul {
    margin-top: 15px;
    font-size:0;
}
.history-kinds li {
    display:inline-block;
    width:49%;
    height: 100%;
    padding: 52px;
    font-size:18px;
    color:#282828;
    border:5px solid #f4f4f4;
    box-sizing: border-box;
    vertical-align: middle;
    cursor: pointer;
}
.history-kinds li:hover {
    border-color: #00934B;
}
.history-kinds li:nth-child(even) {
    margin-left:2%;
}
.history-kinds li > img {
    float:left;
    width:80px;
    margin-right:20px;
}
.history-kinds li > div {
    float:left;
    padding-top: 18px;
}
.history-kinds li > div * {
    font-size:18px;
}
.history-kinds li > a {
    font-size:60px;
    font-weight:300;
    line-height:1.3;
    float:right;
    transition: all .3s linear;
}
.history-kinds li > a:hover, .history-kinds li:hover a {
    -webkit-animation: rotate-center 0.5s 1 ease-out;
    color:#00934B;
}
@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
/*history*/

/*service*/
.sv-list {
    float: left;
    position: relative;
    padding: 45px 35px;
    width: 49%;
    height: 270px;
    margin-bottom:24px;
    border: 5px solid #f4f4f4;
    transition: all 0.4s ease-out;
}
.sv-list:nth-child(even) {
    margin-left:2%;
}
.sv-list > a {
    font-size:24px;
    font-weight:bold;
    color:#000;
}
.sv-list > .sub-txt {
    width:70%;
    padding-top:10px;
    font-size:18px;
    color:#5a5a5a;
    font-weight:400;
}
.sv-list .btn-area {
    display:block;
    padding:12px 20px;
    position: absolute;
    right:20px;
    bottom:30px;
    font-size:16px;
    border:1px solid #838383;
    border-radius:4px;
}
.sv-list:hover {
    border-color: #00934B;
}
.sv-list.ready:hover {
    border-color:#f4f4f4;
}
.sv-list.ready {
    background:#f4f4f4;
}
.sv-list:hover .btn-area {
    border-color: #00934B;
    background:#00934B;
    color:#fff;
}
.sv-list:hover .btn-area a {
    color:#fff;
}
.sv-wrap:after {
    content:"";
    display:block;
    clear:both;
}
.sv-nav, .sv-nav-fixed {
    float:left;
    font-size:17px;
    line-height:2;
}
.sv-nav-fixed {
    position: fixed;
    top: 150px;
}
.sv-nav a, .sv-nav-fixed a {
    display: block;
    color:#777;
    padding-bottom: 10px;
}
.sv-nav-fixed a:active, .sv-nav-fixed a.on, .sv-nav li.on a {
    font-weight: bold;
    color:#00934B;
}
.sv-contents {
    width:calc(80% - 50px);
    float:right;
}
.sv-contents-fixed {
    margin-top: 150px;
}
.sv-contents > div + div {
    margin-top:120px;
}
.sv-contents > div h6 {
    font-size:24px;
    color:#000;
    margin-bottom:30px;
}
.sv-contents p {
    font-size:16px;
    line-height:1.5;
    color:#282828;
}
.sv-contents p > img {
    margin-top:14px;
}
.sv-contents strong {
    display:block;
    padding-bottom:5px;
    font-size: 18px;
}
.sv-contents p + p {
    margin-top:50px;
}
.sv-in:after {
    content:"";
    display:block;
    clear:both;
}
.sv-table {
    width: 100%;
    margin: 20px auto;
    border-top: 2px solid #000;
}
.sv-table th {
    padding: 14px 16px;
    font-size: 16px;
    line-height: 1.4;
    font-weight: bold;
    color: #282828;
    text-align: center;
    background: #f9f9f9;
    border-right: 0.5px solid #DFDFDF;
    border-bottom: 0.5px solid #DFDFDF;
    word-break: break-all;
    vertical-align: middle;
}
.sv-table td {
    padding:14px 16px;
    font-size:16px;
    line-height: 1.4;
    text-align: center;
    border-right: 0.5px solid #DFDFDF;
    border-bottom: 0.5px solid #DFDFDF;
    vertical-align: middle;
}
.sv-table th:last-child, .sv-table td:last-child {
    border-right:0;
}
.sv-halftable td {
    text-align:left;
}
.sv-goback a > * {
    display: inline-block;
    margin-right:10px;
    vertical-align: middle;
}
/*service*/

/*sv-screen*/
.sv-subBox {
    width:100%;
    padding:3% 10%;
    border:3px solid #00934B;
    background: #F6FBF8;
    font-size:18px;
    line-height:1.6;
    text-align: center;
}
.sv-contents > div.sv-subBox, .sv-contents > div:last-child {
    margin-top:80px;
}
.consulting {
    margin-top: 10px;
}
.consulting > * {
    display: inline-block;
    padding:0 16px;
    text-align: center;
    vertical-align: middle;
}
/*sv-screen*/

/*notice*/
.section-in .notice-container {
    padding: 0;
    border-top: 2px solid #000;
    border-bottom: 0.5px solid #DFDFDF;
    margin-bottom: 30px;
}
.section-in .notice-container > div span, .section-in .notice-container > div div {
    padding-top: 0;
}
.section-in .notice-container > div div.notice-tit {
    float: left;
    width:80%;
    font-size:24px;
}
.section-in .notice-container > div div.notice-date {
    float:right;
    width:20%;
    font-size:18px;
    line-height: 1.8;
    text-align:right;
    color:#8c8c8c;
}
.notice-top {
    padding:30px;
    border-bottom: 1px solid #DDDDDD;
}
.notice-top:after {
    content:"";
    display:block;
    clear:both;
}
.notice-txt {
    padding:40px;
    font-size:18px;
    line-height: 1.6;
    word-break: keep-all;
}
.section-in .notice-container p {
    width: 100%;
    padding-bottom:30px;
}
.basic-table.sub-table.notice-table {
    min-height:auto;
}
.basic-table.sub-table.notice-table td {
    vertical-align: middle;
}
.basic-table.sub-table.notice-table td.title {
    width: 75%;
    white-space: nowrap;
    overflow: hidden;
}
.basic-table.sub-table.notice-table td.title a {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 51%;
}
/*notice*/

.customer a {
    display: block;
    width:302px;
    height:70px;
    margin:80px auto;
    line-height:68px;
    border-radius: 50px;
    background:#fff;
    border:2px solid #00934B;
    font-size:22px;
    font-weight:bold;
    color:#00934B;
    text-align: center;
    vertical-align: initial;
    transition: all .3s ease-in;
    box-sizing: border-box;
}
.customer a:hover {
    background:#00934B;
    color:#fff;
}
/*responsive*/
@media all and (max-width:1280px) {
    .c-txt, .v-txt p, .cs-txt  {
        font-size:16px;
        line-height: 1.8;
    }
    .cs-txt strong {
        font-size:20px;
        line-height: 1.4;
    }
    .sc-icon {
        margin-bottom: 10px;
    }
    .sc-icon img {
        width:70px;
    }
    .section-in .notice-container > div div.notice-tit {
        font-size:20px;
    }
    .section-in .notice-container > div div.notice-date, .notice-container .notice-txt p {
        font-size: 16px;
    }
    .notice-top {
        padding:30px 15px;
    }
    .notice-txt {
        padding:40px 15px;
    }
    .sv-contents > div h6 {
        font-size:20px;
    }
    .customer a {
        font-size:18px;
        width: 240px;
        height: 60px;
        line-height: 60px;
    }
}

@media all and (max-width:1024px) {
    .download a {
        width:190px;
        height:60px;
        line-height: 58px;
    }
    .download a img {
        width:118px;
    }
    .cs-service ul li {
        width:100%;
        float:none;
    }
    .cs-service ul li:nth-child(even) {
        margin-left: 0;
    }
    .v-img {
        width:90%;
    }
    .c-txt, .v-txt p, .cs-txt  {
        font-size:15px;
    }
    .c-txt strong, .v-txt strong {
        font-size:24px;
    }
    .c-txt p + p {
        margin-top: 20px;
    }
    .phone-ex {
        width: 48%;
        margin-left:0;
    }
    .smart-sv .c-txt {
        margin-top: 10px;
    }
    .tabs li {
        width:172px;
        height:44px;
        line-height: 42px;
        font-size:16px;
        margin-right:10px;
    }
    .sv-list {
        height:258px;
    }
    .sv-list > a {
        font-size:20px;
    }
    .sv-list > .sub-txt {
        width:82%;
        font-size:15px;
    }
    .sv-list .btn-area {
        font-size:15px;
        padding:8px 16px;
        right: 36px;
        bottom: 44px;
    }
    .sv-nav, .sv-contents, .sv-nav-fixed, .sv-contents-fixed {
        width:100%;
        font-size:15px;
    }
    .sv-nav li, .sv-nav-fixed li {
        display: inline-block;
        margin-right:14px;
        font-size: 16px;
        border-radius: 4px;
    }
    .sv-nav-fixed {
        width: 90%;
        top:68px;
        background:#fff;
    }
    .sv-nav-fixed a {
        padding:14px 0;
    }
    .sv-contents {
        margin-top:30px;
    }
    .sv-contents strong {
        font-size:16px;
    }
    .sv-contents p, .sv-subBox {
        font-size:15px;
        line-height: 1.8;
    }
    .sv-contents p + p {
        margin-top:30px;
    }
    .sv-table th, .sv-table td, .history-kinds li > div * {
        font-size:15px;
        word-break: keep-all;
    }
    .sv-goback a > img {
        width:17px;
    }
    .sv-goback h1 {
        font-size:15px !important;
    }
    .sv-contents > div.sv-subBox, .sv-contents > div:last-child {
        margin-top: 50px;
    }
    .history-kinds li {
        padding:20px;
    }
    .history-kinds li > div {
        padding-top: 4px;
    }
    .history-kinds li > img {
        width:50px;
    }
    .history-kinds li > a {
        font-size: 36px;
        line-height: 1.4;
    }
    .history-in .c-txt {
        width:90%;
    }
}

@media all and (max-width:978px) {
    #sub-container .section-in.smart-sv {
        display: block;
    }
    .phone-ex {
        width:70%;
        float: none;
        margin:0 auto 30px;
    }
    .smart-sv .c-txt {
        width: 100%;
        float: none;
        text-align: center;
    }
    .download a {
        width:164px;
        height:50px;
        line-height: 48px;
        margin-bottom: 10px;
    }
    .download a img {
        width:108px;
    }
    .c-txt {
        width: 100%;
        padding-left: 5%;
        z-index: 9;
    }
    .c-img img {
        position: absolute;
        right: 0;
        top: -152px;
    }
    .notfound h1 {
        font-size:300px;
    }
    .customer a {
        font-size: 16px;
        width: 222px;
    }
}

@media all and (max-width:767px) {
    .c-txt strong, .v-txt strong {
        font-size:20px;
    }
    .history-in .c-txt {
        width: 100%;
    }
    .history-kinds li {
        display: block;
        width: 100%;
    }
    .history-kinds li:nth-child(even) {
        margin-left: 0;
        margin-top: 15px;
    }
    .history-kinds li:after {
        content:"";
        display: block;
        clear: both;
    }
    .sv-list {
        width:100%;
        height:234px;
        float:none;
    }
    .tabs li {
        margin-bottom:10px;
    }
    .sv-nav-fixed {
        top: 58px;
    }
    #sub-container .section-in.sv-in {
        padding-top: 0;
    }
    .section-in .notice-container > div div.notice-tit, .section-in .notice-container > div div.notice-date {
        width:100%;
    }
}

@media all and (max-width:640px) {
    .v-img {
        display: none;
    }
    .v-img.mobile {
        display: block;
        width: 100%;
        margin: 40px auto 30px;
    }
    .v-txt p {
        width: 100%;
    }
    .cs-txt strong {
        font-size:18px;
    }
    .tabs {
        margin:20px 0;
    }
    .tabs li {
        width: auto;
        padding: 0 10px;
    }
    .basic-table.sub-table td:nth-child(3).middle {
        white-space: nowrap;
    }
    .phone-ex {
        width:90%;
    }
    .history-kinds + .history-kinds {
        margin-top: 15px;
    }
    .sv-table td:last-child {
        white-space: nowrap;
    }
    .notfound {
        padding-top: 5vh;
    }
    .notfound h1 {
        font-size:240px;
    }
    .notfound p {
        font-size:22px;
        margin-top: -14px;
    }
    .notfound small {
        width:80%;
        font-size: 15px;
    }
    .notfound a {
        width:150px;
        height:46px;
        line-height: 46px;
        font-size:15px;
    }
    .middle, .basic-table th.middle, .basic-table.sub-table td.middle {
        display: none;
    }
    .basic-table.sub-table.notice-table td.title a {
        width:90%;
    }
    .notice-top {
        padding: 18px 15px;
    }
    .notice-txt {
        padding: 28px 15px;
    }
    .customer a {
        font-size: 16px;
        width: 198px;
        height: 48px;
        line-height: 46px;
    }
}

@media all and (max-width:480px) {
    .phone-ex {
        width: 100%;
    }
    .c-img img {
        top: -98px;
    }
    .cs-service ul li {
        display: block;
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid #e0e0e0;
    }
    .cs-service ul li:last-child {
        border-bottom: 0;
    }
    .cs-txt strong {
        margin-bottom: 15px 0;
    }
    .sv-list {
        padding:24px 16px;
    }
    .sv-list > .sub-txt {
        width:90%;
    }
    .sv-list .btn-area {
        right:16px;
        bottom: 24px;
    }
    .sv-nav li, .sv-nav-fixed li {
        font-size:14px;
    }
    .notfound {
        height: 100%;
        padding: 50px 0 50px;
    }
    .notfound h1 {
        font-size: 150px;
    }
    .notfound p {
        width:80%;
        font-size:18px;
        margin: 0 auto;
    }
    .notice-container .notice-txt p {
        font-size:15px;
    }
    .section-in .notice-container > div div.notice-date {
        font-size:14px;
    }
    .sv-nav-fixed li:nth-child(1) a, .sv-nav-fixed li:nth-child(2) a {
        padding-bottom: 7px;
    }
    .sv-nav-fixed li:nth-child(3) a, .sv-nav-fixed li:nth-child(4) a {
        padding-top: 7px;
    }
}

@media all and (max-width:375px) {
    .c-img img {
        top: -62px;
    }
    @keyframes bounce {
        0%   { transform: translateY(0); }
        50%  { transform: translateY(-80px); }
        100% { transform: translateY(0); }
    }
}