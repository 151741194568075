.map_tool_wrapper{
    position: absolute;
    top: 10px;
    width: 260px;
    height: 90px;
    z-index: 9900;
    right: 620px;
}

.map_btn_wrapper{
    display: flex;
    justify-content: space-around;
    background-color: #99999990;
    border-radius: 10px;
    height: 80px;
    align-items: center;
}

.map_btn_wrapper > div > span{
    width: 70px;
    height: 70px;
    border-radius: 50px;
    border: 1px solid black;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
    position: relative;
    margin: 20px 0;
}

.map_option_wrapper{
    display: flex;
    flex-direction: column;
    position: relative;
}
.map_option_wrapper:hover > span{
    font-weight: bold;
    background-color: white;
}

.map_option_wrapper:hover .map_option{
    display: block;
}

/* map_option */
.map_option{
    display: none;
    background-color: white;
    border-radius: 10px;
    border: 1px solid black;
    padding: 10px;
    margin-top: 10px;
    width: 100px;
    position: absolute;
    top: 85px;
    left: 50%;
    transform: translateX(-50%);
}
.map_option span{
    display: block;
    margin: 5px 0;
    cursor: pointer;
}

.map_option span:hover{
    font-weight: bold;
}