.pck-navbar {
    font-family: 'Robot', sans-serif;
    width: 100%;
    height: 73px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #222222;
    color: white;
    font-weight: bold;
}

.pck-logo {
    margin-left: 1.5rem;
    letter-spacing: 0.08rem;
    font-size: 15px;
}

.pck-zoom-level {
    background-color: rgb(50, 87, 87);
    border-radius: 10px;
    padding: 5px 20px;
}

.pck-user-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pck-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 18px;
    margin-right: 18px;
    overflow: visible;
}

.pck-dropdown-wrapper {
    min-width: 200px;
    position: absolute;
    padding-top: 10px;
    top: 0;
    right: 15px;
    z-index: 999999;
    overflow: visible;
    transform: scaleY(0);
}

.pck-user-container:hover .pck-dropdown-wrapper {
    transform: scaleY(1);
    top: 73px
}
.pck-dropdown {
    margin-top: 1rem;
    color: white;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.818);
    padding: .5rem 1rem;
}


.pck-dropdown li {
    padding: .5rem 1rem;
    border-radius: 5px;
    transition: .3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.pck-dropdown li:hover {
    background-color: rgb(81, 81, 81);
}