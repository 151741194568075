.wrap{
    position: relative;
    width: 100%;
    height: 100%;
}

.pck-platform {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.pck-main {
    position:absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 53px
}